import { Link as GatsbyLink } from "gatsby-plugin-react-i18next";
import {
  LeadSection,
  Button,
  Post,
  CardList,
  Card,
  PostContentsContainer,
  Link,
} from "@lleed-partners/factor";
import {
  Partnership32,
  Help32,
  Chat32,
  ArrowRight32,
} from "@carbon/icons-react";
import { graphql } from "gatsby";
import { useI18next, Trans } from "gatsby-plugin-react-i18next";
import { Icon } from "../../fragments/Icon";
import { Layout } from "../../layouts";
import { Helmet } from "gatsby-plugin-react-i18next";
import Obfuscate from "react-obfuscate";

export default () => {
  const { t } = useI18next();

  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content={t("lleedpartners.contact-description")}
        />
        <title>{t("Contact us")}</title>
      </Helmet>
      <LeadSection
        intent="grey"
        padTop
        title={t("Get in touch")}
        subtitle={t(
          "Whether it is for a business enquiry, a need for help or just to discuss some topics, we will make sure that one of our business experts responds as soon as possible."
        )}
        cta={
          <>
            <Button
              intent="primary"
              rightIcon={<Icon icon={<ArrowRight32 />} />}
              as={"a"}
              href="#business"
            >
              {t("Business enquiry")}
            </Button>
            <Button
              intent="ghost"
              as={"a"}
              href="#support"
              rightIcon={<Icon icon={<ArrowRight32 />} />}
            >
              {t("I need help")}
            </Button>
          </>
        }
      />
      <Post>
        <h2>{t("Contact options")}</h2>
        <p>
          {t(
            "To make sure we connect you with the most relevant team at Lleed & Partners, please select your main reason for contacting us today."
          )}
        </p>
        <PostContentsContainer position="center-full">
          <CardList intent="white" size="xLarge" bordered>
            <Card
              intent="grey"
              id="business"
              icon={<Icon icon={<Partnership32 />} />}
              title={t("Business enquiry")}
              cta={
                <>
                  <Button
                    rightIcon={<Icon icon={<ArrowRight32 />} />}
                    as={GatsbyLink}
                    to="/contact/business/"
                  >
                    {t("Online form")}
                  </Button>
                  <br />
                  <br />
                  Email
                  <br />
                  <Link
                    as={Obfuscate}
                    email="business@lleedpartners.com"
                    headers={{
                      subject: t("Business enquiry"),
                      body: t(
                        "Please, let us know more about your business and needs, and how we can reach back to you."
                      ),
                    }}
                  >
                    <Obfuscate element="span">
                      business@lleedpartners.com
                    </Obfuscate>
                  </Link>
                </>
              }
            >
              {t(
                "If you would like to discuss your needs with our business experts. Lleed & Partners strive to fulfill your needs with comprehensive and appropriate solutions."
              )}
            </Card>
            <Card
              id="support"
              icon={<Icon icon={<Help32 />} />}
              title={t("Support")}
              cta={
                <>
                  <Button
                    rightIcon={<Icon icon={<ArrowRight32 />} />}
                    as={GatsbyLink}
                    to="/contact/support/"
                  >
                    {t("Raise ticket online")}
                  </Button>
                  <br />
                  <br />
                  {t("Email")}
                  <br />
                  <Link
                    as={Obfuscate}
                    email="support@lleedpartners.com"
                    headers={{
                      subject: t("Support request"),
                      body: t(
                        "Please, let us know what are the issues we can help you with today."
                      ),
                    }}
                  >
                    <Obfuscate element="span">
                      support@lleedpartners.com
                    </Obfuscate>
                  </Link>
                </>
              }
            >
              {t(
                "If you need help regarding a technical or administrative aspect of an existing Lleed & Partners contract, we will make sure that your request is triaged to the right hands as fast as possible."
              )}
            </Card>
            <Card
              id="general"
              icon={<Icon icon={<Chat32 />} />}
              title={t("General enquiry")}
              cta={
                <>
                  <Button
                    rightIcon={<Icon icon={<ArrowRight32 />} />}
                    as={GatsbyLink}
                    to="/contact/general/"
                  >
                    {t("Start a discussion")}
                  </Button>
                  <br />
                  <br />
                  {t("Email")}
                  <br />
                  <Link
                    as={Obfuscate}
                    email="contact@lleedpartners.com"
                    headers={{
                      subject: t("Getting in touch"),
                      body: t(
                        "Please, let us know what subject you would like to discuss. Our teams will happily answer any question."
                      ),
                    }}
                  >
                    <Obfuscate element="span">
                      contact@lleedpartners.com
                    </Obfuscate>
                  </Link>
                </>
              }
            >
              {t(
                "If you would like to discuss some aspect of your business, get more information about our operations or for press purposes. We will get back to you in the promptest fasion."
              )}
            </Card>
          </CardList>
        </PostContentsContainer>
        <h2>{t("Opening hours")}</h2>
        <p>
          <Trans t={t}>
            <b>General and business enquiries</b>
            <br />
            Monday to Friday, from 8:00 AM to 8:00 PM UTC+1
            <br />
            <br />
            <b>Support requests</b>
            <br />
            24/7
            <br />
          </Trans>
        </p>
        <p></p>
      </Post>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
